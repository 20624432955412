import React from 'react'
import styles from './Confidence.module.scss'
import { motion } from "framer-motion"


const titleAnimation = {
    hidden: {
        y: 200,
        opacity: 0,
    },

    visible: {
        y: 0,
        opacity: 1,
        transition: { duration: .5 }
    }
}

const cardAnimation = {
    hidden: {
        y: 100,
        opacity: 0,
    },
    visible: {
        y: 0,
        opacity: 1,
        transition: { duration: .7}
    } 
}



const Confidence = () => {

    return (
        <motion.section 
            className={styles.main}
            initial='hidden'
            whileInView='visible'
            viewport={{amount: 0.1, once: true}}
            >
            <div className={styles.box}>
                <motion.h2 className={styles.title} variants={titleAnimation}>Основные преимущества <span>лазерного нанесения</span> изображения</motion.h2>
                <motion.ul className={styles.list}>
                    <motion.li 
                        className={styles.item}
                        variants={cardAnimation}
                        initial='hidden'
                        whileInView='visible'
                        viewport={{amount: 0.05, once: true}}
                        >
                        <div className={styles.item_line}>
                            <div className={styles.item_box} >
                                <p className={styles.item_number}>01</p>
                                <p className={styles.item_title}>Долговечность изделия</p>
                            </div>
                            <p className={styles.item_text}>Рисунок не наносится на поверхность, а выжигается в ней на некоторую небольшую глубину. Полученный узор нельзя ничем стереть, даже если сувенир, например, зажигалка постоянно будет находиться в кармане. Для памятных подарков это особенно важно, поскольку  <span>нанесенная уникальная гравировка останется на долгие годы и сохранит свою привлекательность даже через долгое время.</span></p>
                        </div>
                    </motion.li>
                    <motion.li 
                        className={styles.item}
                        variants={cardAnimation}
                        initial='hidden'
                        whileInView='visible'
                        viewport={{amount: 0.05, once: true}}
                        >
                        <div className={styles.item_line}>
                            <div className={styles.item_box} >
                                <p className={styles.item_number}>02</p>
                                <p className={styles.item_title}>Презентабельность и уникальность</p>
                            </div>
                            <p className={styles.item_text}>Даже самые простые предметы после нанесения лазерной гравировки становятся более презентабельными и дорогими. После лазерной гравировки можно получить уникальное изделие, которого больше нет ни у кого. <span>Это идеальный вариант для подарка как себе, так и близким, </span>если есть желание оставить о себе хорошие и приятные воспоминания.</p>
                        </div>    
                    </motion.li>
                    <motion.li 
                        className={styles.item}
                        variants={cardAnimation}
                        initial='hidden'
                        whileInView='visible'
                        viewport={{amount: 0.05, once: true}}
                        >
                            <div className={styles.item_line}>
                                <div className={styles.item_box} >
                                    <p className={styles.item_number}>03</p>
                                    <p className={styles.item_title}>Никаких повреждений</p>
                                </div>
                                <p className={styles.item_text}>При лазерной гравировке исключены какие-либо повреждения, поскольку все происходит под управлением программного комплекса, исключающего человеческий фактор. <span>Это гарантирует, что обрабатываемая поверхность не будет деформирована. </span>Заготовка нагревается точечно, поэтому лазер никак не влияет на форму и другие свойства.</p>
                            </div>
                    </motion.li>
                    <motion.li 
                        className={styles.item}
                        variants={cardAnimation}
                        initial='hidden'
                        whileInView='visible'
                        viewport={{amount: 0.05, once: true}}
                        >
                            <div className={styles.item_line}>
                                <div className={styles.item_box} >
                                <p className={styles.item_number}>04</p>
                                <p className={styles.item_title}>Любое количество изделий</p>
                                </div>
                                <p className={styles.item_text}>Оборудование для лазерной гравировки позволяет работать как с одним изделием, так и с целой партией. Поэтому к нам обращаются и обычные покупатели, и компании, которые заказывают малые и крупные тиражи сувениров для сотрудников, корпоративных клиентов, партнеров или брендированной продукции. <span>Чем больше партия, тем выгоднее цена одной единицы.</span></p>
                            </div>
                    </motion.li>
                </motion.ul>
            </div>
        </motion.section>
    )
}

export default Confidence