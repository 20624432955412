import img_1 from '../image/Portfolio/img_4.webp'
import img_2 from '../image/Portfolio/img_5.webp'
import img_3 from '../image/Portfolio/img_6.webp'
import img_4 from '../image/Portfolio/img_1.webp'
import img_5 from '../image/Portfolio/img_2.webp'
import img_6 from '../image/Portfolio/img_3.webp'
import img_7 from '../image/Portfolio/img_7.webp'
import img_8 from '../image/Portfolio/img_8.webp'
import img_9 from '../image/Portfolio/img_9.webp'
import img_10 from '../image/Portfolio/img_10.webp'
import img_11 from '../image/Portfolio/img_11.webp'
import img_12 from '../image/Portfolio/img_12.webp'
import img_13 from '../image/Portfolio/img_13.webp'

export const arrPortfolio = [{image: img_1, title: 'Гравировка'}, {image: img_4, title: 'Гравировка'}, {image: img_9, title: 'Гравировка'},  {image: img_6, title: 'Гравировка'},  {image: img_8, title: 'Гравировка'}, {image: img_7, title: 'Гравировка'}, {image: img_5, title: 'Гравировка'},{image: img_2, title: 'Гравировка'},  {image: img_3, title: 'Гравировка'}, {image: img_10, title: 'Гравировка'}, {image: img_11, title: 'Гравировка'}, {image: img_12, title: 'Гравировка'}, {image: img_13, title: 'Гравировка'},];

export const arrAdvantages = [{text:'Многолетний ОПЫТ'}, {text:'Соблюдение СРОКОВ'}, {text:'Скидки за ОБЪЕМ'}, {text:'Гарантированное КАЧЕСТВО'}, {text:'Профессиональное ОБОРУДОВАНИЕ'}, {text:'Индивидуальный ПОДХОД'},];

