import React, {useCallback, useState} from 'react'
import app from './App.module.scss'
import Main from '../../pages/Main/Main'
import Header  from '../Header/Header'
import Footer  from '../Footer/Footer'
import { ModalImage } from '../ModalImage/ModalImage'
import ButtonCommunication from '../ButtonCommunication/ButtonCommunication'


import UpButton from '../UpButton/UpButton'

function App() {

  const [isPopupImageOpen, setIsPopupImageOpen] = useState(false)

  const vh = window.innerHeight / 100;
  document.documentElement.style.setProperty('--vh', `${vh}px`);

  const handleImageClose = useCallback(() => {
    setIsPopupImageOpen(false)
  }, []);

  const handleImageOpen = useCallback((index, arr) => {
      setIsPopupImageOpen(true)
  }, [])



  return (
    <div className={app.page}>
      <Header
      />
      <Main
        isImageOpen={handleImageOpen}
      />
      <Footer/>
      <UpButton/>
      <ButtonCommunication/>
      {isPopupImageOpen && 
      <ModalImage
        onClose={handleImageClose}
      />}
    </div>
  );
}

export default App;
