import React, {useEffect} from 'react'
import { createPortal } from "react-dom"
import modal from './ModalImage.module.scss'
import ModalPortfolio from '../ModalPortfolio/ModalPortfolio'
import { v4 as uuidv4 } from 'uuid'
import {arrPortfolio} from '../../utils/data'


export const ModalImage = ({arr, onClose}) => {

    const modalRoot = document.getElementById("modals")

    useEffect(() => {
        const handleEscClose = (evt) =>{
            if (evt.key ==='Escape')  onClose()
        }
        document.addEventListener('keydown', handleEscClose)
        return () => document.removeEventListener('keydown', handleEscClose)
    }, [onClose])

    return createPortal (
        <ModalPortfolio onClick={onClose}>
            <ul className={modal.modal} onClick={evt => evt.stopPropagation()}>
                {arrPortfolio.map((item, index) => {
                const keyUid = uuidv4()
                return(
                    <li className={modal.cell} key={keyUid}>
                        <img className={modal.image} src={item.image} alt = {item.title}/>
                    </li>
                )
            })}
            </ul>
        </ModalPortfolio>
        ,modalRoot
    );
};