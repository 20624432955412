import React from 'react'
import styles from './InitialPage.module.scss'


const InitialPage = () => {
    return (
        <section className={styles.main} id='initial'>
            <div className={styles.overlay}>
                <div className={styles.container}>
                    <p className={styles.text}>Нужна лазерная гравировка или маркировка?</p>
                    <h1 className={styles.title}><span>профессионально выполним</span> лазерную&#160;гравировку любой&#160;сложности</h1>
                    <ul className={styles.list}>
                        <li className={styles.item}>
                            <p className={styles.check}>&#10003;</p>
                            <p className={styles.item_text}>Гравировка сохранит свой первозданный вид на многие годы</p>
                        </li>
                        <li className={styles.item}>
                            <p className={styles.check}>&#10003;</p>
                            <p className={styles.item_text}>Возможность нанесения на любые поверхности и изделия</p>
                        </li>
                        <li className={styles.item}>
                            <p className={styles.check}>&#10003;</p>
                            <p className={styles.item_text}>Точность и чёткость нанесения даже мелких деталей изображения</p>
                        </li>
                    </ul>
                    <div className={styles.box_button}>
                        <a className={styles.button} target='_blank' rel='noopener noreferrer' href='https://wa.me/+74950800707' aria-label='связаться вацап'>получить консультацию</a>
                    </div>
                </div>
            </div> 
        </section>
    )
}

export default InitialPage