import React from 'react'
import { motion } from "framer-motion"
import styles from './Services.module.scss'
import image_1 from '../../image/Services/img_1.webp'
import image_2 from '../../image/Services/img_2.webp'
import image_3 from '../../image/Services/img_3.jpg'
import image_4 from '../../image/Services/img_4.webp'

const titleAnimation = {
    hidden: {
        y: 200,
        opacity: 0,
    },

    visible: {
        y: 0,
        opacity: 1,
        transition: { duration: .3 }
    }
}

const cardAnimation = {
    hidden: {
        y: 100,
        opacity: 0,
    },
    visible: {
        y: 0,
        opacity: 1,
        transition: { duration: .7}
    } 
}

const Services = () => {

    return (
        <motion.section 
            className={styles.main} 
            id='Services'
            initial='hidden'
            whileInView='visible'
            viewport={{amount: 0.1, once: true}}
            >
                <div className={styles.box}>
                    <motion.h2 className={styles.title} variants={titleAnimation}>Высококачественная<span> лазерная&#160;гравировка</span></motion.h2>
                    <motion.ul className={styles.list}>
                    <motion.li 
                        className={styles.cell}
                        variants={cardAnimation}
                        initial='hidden'
                        whileInView='visible'
                        viewport={{amount: 0.05, once: true}}
                        >
                        <div className={styles.cell_box}>
                            <img className={styles.cell_image} src={image_1} alt='стружка'/>
                        </div>
                        <div className={styles.cell_info}>
                            <h3 className={styles.cell_subtitle}>Гравировка на металле</h3>
                            <p className={styles.cell_text}>Работаем с любыми металлами: cталь, нержавейка, медь, латунь, алюминий, золото, серебро, платина.</p>
                            <a className={styles.cell_button} target='_blank' rel='noopener noreferrer' href='https://wa.me/+74950800707' aria-label='связаться вацап'>заказать гравировку</a>
                        </div>
                    </motion.li>    
                    <motion.li 
                        className={styles.cell}
                        variants={cardAnimation}
                        initial='hidden'
                        whileInView='visible'
                        viewport={{amount: 0.05, once: true}}
                        >
                        <div className={styles.cell_box}>
                            <img className={styles.cell_image} src={image_2} alt='стружка'/>
                        </div>
                        <div className={styles.cell_info}>
                            <h3 className={styles.cell_subtitle}>Гравировка на пластике</h3>
                            <p className={styles.cell_text}>Лазерная гравировка на пластике - надежный и точный способ нанесения надписей и изображений любой сложности. </p>
                            <a className={styles.cell_button} target='_blank' rel='noopener noreferrer' href='https://wa.me/+74950800707' aria-label='связаться вацап'>заказать гравировку</a>
                        </div>
                    </motion.li>
                    <motion.li 
                        className={styles.cell}
                        variants={cardAnimation}
                        initial='hidden'
                        whileInView='visible'
                        viewport={{amount: 0.05, once: true}}
                        >
                        <div className={styles.cell_box}>
                            <img className={styles.cell_image} src={image_3} alt='стружка'/>
                        </div>
                        <div className={styles.cell_info}>
                            <h3 className={styles.cell_subtitle}>Гравировка на коже</h3>
                            <p className={styles.cell_text}>Гравировка на коже - это один из способов придать кожаным изделиям индивидуальность и уникальность.</p>
                            <a className={styles.cell_button} target='_blank' rel='noopener noreferrer' href='https://wa.me/+74950800707' aria-label='связаться вацап'>заказать гравировку</a>
                        </div>
                    </motion.li>
                    <motion.li 
                        className={styles.cell}
                        variants={cardAnimation}
                        initial='hidden'
                        whileInView='visible'
                        viewport={{amount: 0.05, once: true}}
                        >
                        <div className={styles.cell_box}>
                            <img className={styles.cell_image} src={image_4} alt='стружка'/>
                        </div>
                        <div className={styles.cell_info}>
                            <h3 className={styles.cell_subtitle}>Цветная гравировка</h3>
                            <p className={styles.cell_text}>За счет профессионального и высокоточного оборудования возможно выполнение цветной гравировки на металлах.</p>
                            <a className={styles.cell_button} target='_blank' rel='noopener noreferrer' href='https://wa.me/+74950800707' aria-label='связаться вацап'>заказать гравировку</a>
                        </div>
                    </motion.li>
                    </motion.ul>
                </div>
        </motion.section>
        
    )
}

export default Services