import React from 'react'
import styles from './Map.module.scss'
import { motion } from "framer-motion"
import ButtonBox from '../ButtonBox/ButtonBox'
import DetailMap from '../DetailMap/DetailMap'
import phone from '../../image/Footer/phone.svg'
import email from '../../image/Footer/email-icon.svg'
import address from '../../image/Footer/address.svg'
import time from '../../image/Footer/time.svg'
import company from '../../image/Footer/company.svg'

const titleAnimation = {
    hidden: {
        y: 200,
        opacity: 0,
    },

    visible: {
        y: 0,
        opacity: 1,
        transition: { duration: .5 }
    }
}

const cardAnimation = {
    hidden: {
        y: 100,
        opacity: 0,
    },
    visible: {
        y: 0,
        opacity: 1,
        transition: { duration: .7}
    } 
}


const Map = () => {


    return (
        <motion.section 
            className={styles.main}
            initial='hidden'
            whileInView='visible'
            viewport={{amount: 0.1, once: true}}
            >
            <motion.div className={styles.box}>
                <div className={styles.info}>
                    <motion.h2 className={styles.title} variants={titleAnimation}>Контакты</motion.h2>
                    <motion.div 
                        className={styles.box_contacts}
                        variants={cardAnimation}
                        initial='hidden'
                        whileInView='visible'
                        viewport={{amount: 0.05, once: true}}
                        >
                            <div className={styles.icon_box}>
                                <img className={styles.icon_contacts} src={phone} alt = 'icon'/>
                            </div>
                            <a className={styles.link_phone}  href="tel:+74950800707" target='_blank' rel='noopener noreferrer'>+7 (495) 080-07-07</a>
                    </motion.div>
                    <motion.div 
                        className={styles.box_contacts}
                        variants={cardAnimation}
                        initial='hidden'
                        whileInView='visible'
                        viewport={{amount: 0.05, once: true}}
                        >
                            <div className={styles.icon_box}>
                                <img className={styles.icon_contacts} src={email} alt = 'icon'/>
                            </div>
                            <a className={styles.link_email}  href="mr-carver@bk.ru" target='_blank' rel='noopener noreferrer'>mr-carver@bk.ru</a>
                    </motion.div>
                    <motion.div 
                        className={styles.box_contacts}
                        variants={cardAnimation}
                        initial='hidden'
                        whileInView='visible'
                        viewport={{amount: 0.05, once: true}}
                        >
                            <div className={styles.icon_box}>
                                <img className={styles.icon_address} src={address} alt = 'icon'/>
                            </div>
                            <p className={styles.text}>125367, г. Москва, улица Береговая, д. 4</p>
                    </motion.div>
                    <motion.div 
                        className={styles.box_contacts}
                        variants={cardAnimation}
                        initial='hidden'
                        whileInView='visible'
                        viewport={{amount: 0.05, once: true}}
                        >
                            <div className={styles.icon_box}>
                                <img className={styles.icon_contacts} src={company} alt = 'icon'/>
                            </div>
                            <p className={styles.text}>ИП Снопковский И.Н. ИНН&#160;772742997284</p>
                    </motion.div>
                    <motion.div 
                        className={styles.box_contacts_social}
                        variants={cardAnimation}
                        initial='hidden'
                        whileInView='visible'
                        viewport={{amount: 0.05, once: true}}
                        >
                            <div className={styles.icon_box}>
                                <img className={styles.icon_contacts} src={time} alt = 'icon'/>
                            </div>
                            <p className={styles.text}>Пн-Пт 10:00 - 20:00 Сб&#8205;-&#8205;Вс&#160;по звонку</p>
                            <ButtonBox/>
                    </motion.div>
                </div>
                <motion.div 
                    className={styles.box_map}
                    variants={titleAnimation} 
                    initial='hidden'
                    whileInView='visible'
                    viewport={{amount: 0.05, once: true}}
                    >
                    <DetailMap/>
                </motion.div>
            </motion.div>
        </motion.section>
    )
}

export default Map