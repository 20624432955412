import React from 'react'
import { motion } from "framer-motion"
import styles from './Price.module.scss'

const titleAnimation = {
    hidden: {
        y: 200,
        opacity: 0,
    },

    visible: {
        y: 0,
        opacity: 1,
        transition: { duration: .3 }
    }
}

const Price = () => {

    return (
        <motion.section 
            className={styles.main} 
            id='Services'
            initial='hidden'
            whileInView='visible'
            viewport={{amount: 0.1, once: true}}
            >
                <div className={styles.box}>
                    <motion.h2 className={styles.title} variants={titleAnimation}>Порядок цен на<span> лазерную&#160;гравировку</span></motion.h2>
                    <motion.ul className={styles.list}>
                        <li className={styles.item}>
                            <p className={styles.text}>Жетон / подвеска / брелок <span>(одна&#160;сторона)</span></p>
                            <p className={styles.price}>от&#160;350₽</p>
                        </li>
                        <li className={styles.item}>
                            <p className={styles.text}>Жетон / подвеска / брелок <span>(две&#160;стороны)</span></p>
                            <p className={styles.price}>от&#160;550₽</p>
                        </li>
                        <li className={styles.item}>
                            <p className={styles.text}>Жетон / подвеска / брелок <span>(цветной)</span></p>
                            <p className={styles.price}>от&#160;1000₽</p>
                        </li>
                        <li className={styles.item}>
                            <p className={styles.text}>Адресник кость / круг <span>(одна&#160;сторона)</span></p>
                            <p className={styles.price}>от&#160;350₽</p>
                        </li>
                        <li className={styles.item}>
                            <p className={styles.text}>Адресник кость / круг <span>(две&#160;стороны)</span></p>
                            <p className={styles.price}>от&#160;550₽</p>
                        </li>
                        <li className={styles.item}>
                            <p className={styles.text}>Адресник кость / круг <span>(авторский)</span></p>
                            <p className={styles.price}>от&#160;900₽</p>
                        </li>
                        <li className={styles.item}>
                            <p className={styles.text}>Адресник кость / круг <span>(цветной)</span></p>
                            <p className={styles.price}>от&#160;1000₽</p>
                        </li>
                        <li className={styles.item}>
                            <p className={styles.text}>Гравировка на цилиндрах <span>(термосы, кружки и т.д.)</span></p>
                            <p className={styles.price}>от&#160;1200₽</p>
                        </li>
                        <li className={styles.item}>
                            <p className={styles.text}>Гравировка на кольцах</p>
                            <p className={styles.price}>от&#160;800₽</p>
                        </li>
                        <li className={styles.item}>
                            <p className={styles.text}>Гравировка на коже</p>
                            <p className={styles.price}>от&#160;700₽</p>
                        </li>
                        <li className={styles.item}>
                            <p className={styles.text}>Гравировка на пластике</p>
                            <p className={styles.price}>от&#160;1000₽</p>
                        </li>
                        <li className={styles.item}>
                            <p className={styles.text}>Гравировка на клавиатуре ноутбуков</p>
                            <p className={styles.price}>от&#160;1000₽</p>
                        </li>
                        <li className={styles.item}>
                            <p className={styles.text}>Гравировка на ноже</p>
                            <p className={styles.price}>от&#160;900₽</p>
                        </li>
                        <li className={styles.item}>
                            <p className={styles.text}>Гравировка на телефоне <span>(металлический)</span></p>
                            <p className={styles.price}>от&#160;1500₽</p>
                        </li>
                        <li className={styles.item}>
                            <p className={styles.text}>Гравировка на замке</p>
                            <p className={styles.price}>от&#160;600₽</p>
                        </li>
                        <li className={styles.item}>
                            <p className={styles.text}>Гравировка на часах</p>
                            <p className={styles.price}>от&#160;700₽</p>
                        </li>
                        <li className={styles.item}>
                            <p className={styles.text}>Гравировка на айкос</p>
                            <p className={styles.price}>от&#160;1000₽</p>
                        </li>
                        <li className={styles.item}>
                            <p className={styles.text}>Гравировка на серебряных изделиях</p>
                            <p className={styles.price}>от&#160;800₽</p>
                        </li>
                        <li className={styles.item}>
                            <p className={styles.text}>Гравировка на табличках</p>
                            <p className={styles.price}>от&#160;1500₽</p>
                        </li>
                        <li className={styles.item}>
                            <p className={styles.text}>Маркировка тиража</p>
                            <p className={styles.price}>от&#160;5₽</p>
                        </li>
                    </motion.ul>
                </div>
        </motion.section>
        
    )
}

export default Price