import React from 'react'
import { motion } from "framer-motion"
import styles from './Marking.module.scss'
import image from '../../image/Marking/img_1.webp'

const imageAnimation = {
    hidden: {
        scale: 0,
        opacity: 0,
    },

    visible: {
        scale: 1,
        opacity: 1,
        transition: { duration: .8 }
    }
}

const textAnimation = {
    hidden: {
        x: 500,
        opacity: 0,
    },

    visible: {
        x: 0,
        opacity: 1,
        transition: { duration: .6 }
    }
}

const Marking = () => {

    return (
        <motion.section 
            className={styles.main} 
            initial='hidden'
            whileInView='visible'
            viewport={{amount: 0.1, once: true}}
            id='AboutUs'             
            >
            <div className={styles.container}>
                <motion.img className={styles.image} src={image} alt='металл' variants={imageAnimation}/>
                <motion.div className={styles.box_info} variants={textAnimation}>
                        <h2 className={styles.title}>Профессиональная <span>лазерная&#160;маркировка</span> - нам доверяют профессионалы.</h2>
                        <p className={styles.text}>&#8195;Лазерная маркировка – это надежный и прочный способ нанесения изображений на различные поверхности. Независимо от материала, будь то металл, пластик или кожа, лазерная маркировка обеспечивает высокую точность и четкость нанесения. В нашей компании сегодня доступны услуги лазерной маркировки по выгодной цене.</p>
                        <p className={styles.text}>Процесс лазерной маркировки осуществляется при помощи высокоточного лазерного оборудования, которое обеспечивает точность и четкость нанесения изображений. Лазерный луч контролируется компьютерной программой, что позволяет создавать рисунки, надписи, штрих-коды и другие элементы на поверхностях различных форм и размеров.</p>
                        <div className={styles.box_link}>
                            <a className={styles.link} target='_blank' rel='noopener noreferrer' href='https://wa.me/+74950800707' aria-label='связаться вацап'>заказать лазерную маркировку</a>
                        </div>
                    </motion.div>
            </div>
        </motion.section>
    )
}

export default Marking