import React from 'react'
import header from './Header.module.scss'
import logo from '../../image/Header/logo.png'
import ButtonBox from '../ButtonBox/ButtonBox'

const Header = () => {

    return (
        <header className={header.header}>
        <div className={header.box}>
                <div className={header.info}>
                    <div className={header.box_logo}>
                        <a className={header.logo_link} href='initial'>
                            <img className={header.logo} src={logo} alt = 'логотип'/>
                        </a>
                        <div className={header.contacts}>
                            <div className={header.box_social}>
                                <ButtonBox/>
                            </div>
                            <div className={header.box_phone}>
                                <div className={header.call}>
                                    <div className={header.call_pulse}></div>
                                    <p className={header.call_text}>Звоните, мы работаем</p>
                                </div>
                                <a className={header.phone}  href="tel:+74950800707" target='_blank' rel='noopener noreferrer'>+7 495 <span>080-07-07</span></a>
                            </div>
                        </div>
                    </div>
                </div>
        </div>
    </header>    
    );
};

export default Header