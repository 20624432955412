import React from 'react'
import styles from './Main.module.scss'
import InitialPage from '../../components/initialPage/InitialPage'
import Services from '../../components/Services/Services'
import Marking from '../../components/Marking/Marking'
import Confidence from '../../components/Confidence/Confidence'
import Portfolio from '../../components/Portfolio/Portfolio'
import Practical from '../../components/Practical/Practical'
import Map from '../../components/Map/Map'
import SliderAdvantages  from '../../components/SliderAdvantages/SliderAdvantages'
import Price from '../../components/Price/Price'

const Main = ({isOpen, isImageOpen}) => {

    return (
        <section className={styles.main}>
            <InitialPage
                isOpen={isOpen}
            />
            <SliderAdvantages/>
            <Services/>
            <Marking/>
            <Confidence/>
            <Portfolio
                isImageOpen={isImageOpen}
            />
            <Price/>
            <Practical/>
            <Map/>
        </section>
    )
}

export default Main