import React, {useState}  from 'react'
import styles from './ButtonCommunication.module.scss'


const ButtonCommunication = ({isOpen}) => {

    const [visible, setVisible] = useState(false);

    const toggleVisible = () => {
        const scrolled = document.documentElement.scrollTop;
        if (scrolled > 300){
            setVisible(true)
        } 
        else if (scrolled <= 300){
            setVisible(false)
        }
    };

    window.addEventListener('scroll', toggleVisible);

    return (
        <a className={styles.button}   style={{display: visible ? 'flex' : 'none'}} target='_blank' rel='noopener noreferrer' href='https://wa.me/+74950800707' aria-label='связаться вацап'>
            <span className={styles.pulse}></span>
            <span className={styles.pulse_two}></span>
        </a>
    )
}

export default ButtonCommunication