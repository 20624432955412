import React, {useCallback} from 'react'
import styles from './CardImage.module.scss'

const boxAnimation = {
    hidden: {
        scale: 0,
        opacity: 0,
    },
    visible: {
        scale: 1,
        opacity: 1,
        transition: { duration: .7}
    } 
}


const CardImage = ({item, isImageOpen}) => {

    const cardClick = useCallback(() => {
        isImageOpen()
    }, [isImageOpen])

    return (
        <li 
            className={styles.item}  
            onClick={cardClick}
            initial='hidden'
            whileInView='visible'
            viewport={{amount: 0.05, once: true}}
            variants={boxAnimation} 
            >
            <img className={styles.image} src={item.image} alt={item.title}/>
        </li>
    )
}

export default CardImage